<template>
  <div class="project-search">
    <div class="form-box">
      <el-form
        :inline="true"
        ref="form"
        :model="form"
        label-width="100px"
        label-position="right"
      >
        <!-- 漫画类型 -->
        <el-form-item label="漫画タイプ">
          <el-select
            size="mini"
            v-model="form.region"
            placeholder="漫画タイプ"
          >
            <el-option label="読切" value="2"></el-option>
            <el-option label="連載" value="1"></el-option>
          </el-select>
        </el-form-item>
        <!-- 分类 -->
        <el-form-item label="ジャンル">
          <el-select size="mini" v-model="form.type" placeholder="ジャンルを選択して下さい">
            <el-option
              v-for="item in optionArr"
              :key="item.id"
              :label="item.typeb"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 创作/随想 -->
        <el-form-item label="創作/エッセイ">
          <el-select size="mini" v-model="form.creation" placeholder="選択して下さい">
            <el-option label="創作" value="1"></el-option>
            <el-option label="エッセイ" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="製作">
          <el-select size="mini" v-model="form.medal" placeholder="選択して下さい">
            <el-option label="商業" value="1"></el-option>
            <el-option label="自主制作" value="2"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div class="btn-box">
      <el-button size="mini" @click="resetSearch">リセット</el-button>
      <!-- 检索 -->
      <el-button size="mini" type="primary" @click="onSubmit">検索</el-button>
    </div>
    <div class="tab-box">
      <!-- 配对件数 -->
      <div>マッチング件数</div>
      <template>
        <el-table :data="tableData" border style="width: 100%">
          <!-- 匹配度 -->
          <el-table-column prop="similar" label="マッチング度">
            <template slot-scope="scope">
              <span>{{ Math.ceil(scope.row.similar * 100) }}%</span>
            </template>
          </el-table-column>
          <el-table-column prop="title" label="漫画タイトル"> </el-table-column>
            <el-table-column prop="name" label="案件名"> </el-table-column>
            <el-table-column prop="title" label="応募">
                <template scope="scope">
                    <span style="cursor:pointer;color:#187562">応募</span>
                </template>
            </el-table-column>
            <el-table-column prop="status" label="ステータス">
                 <template scope="scope">
                    <span v-if="scope.row.status === 1">作業開始前</span>
                    <span v-if="scope.row.status === 2">作業中</span>
                    <span v-if="scope.row.status === 3">承認待ち</span>
                    <span v-if="scope.row.status === 4">完成</span>
                    <span v-if="scope.row.status === 5">否決</span>
                </template>
            </el-table-column>
         
        </el-table>
      </template>
    </div>
    <el-dialog title="プロジェクト詳細" :visible.sync="projectDesc" width="50%">
      <div>
        {{desc}}
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="projectDesc = false">取　消</el-button>
        <el-button type="primary" @click="projectDesc = false">確　定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import service from "@/service";

export default {
  name: "CratoonProjectProjectsearch",

  data() {
    return {
      form: {
        region: "",
        type: "",
        medal: "",
        creation: "",
      },
      optionArr: [],
      checked: false,
      tableData: [],
      projectDesc: false,
      desc:'',
    };
  },

  mounted() {
    this.getType();
  },

  methods: {
    //获取分类
    getType() {
      service.personal
        .getTypeData()
        .then((res) => {
          if (res.status === 200) {
            this.optionArr = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onSubmit() {
      let params = {
        type: this.form.region,
        typeb: this.form.type,
        create: this.form.creation,
        kind: this.form.medal,
        p: 1,
      };
      service.personal
        .findChapter(params)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.length) {
              this.tableData = res.data;
            } else {
              this.$message.success("データなし");
              this.tableData = [];
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    resetSearch() {
      this.form.region = ''
      this.form.type = ''
      this.form.medal = ''
      this.form.creation = ''
      this.tableData = []
    },
    handleClick(row) {
      this.desc = row.content;
      this.projectDesc = true
    },
  },
};
</script>

<style lang="scss" scoped>
.project-search {
  // width: 100%;
  padding: 20px;
  .form-box {
    padding: 15px;
    ::v-deep.el-form-item {
      margin-bottom: 0;
    }
  }
  .btn-box {
    text-align: right;
    margin-right: 18%;
  }
  .tab-box {
    padding: 0 15px;
    > span {
      font-size: 24px;
    }
    > div {
      margin-bottom: 15px;
    }
  }
}
</style>
